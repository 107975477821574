import React, { useState }from 'react'
import './NavBar.css'
import logo from '../../Assets/chinatown-logo.png'
import Directory from '../../Pages/Directory/directory'
import LandingPage from '../../Pages/LandingPage/LandingPage'
import HomeTo from '../HomeTo/HomeTo'
import AboutUs from '../AboutUs/AboutUs'
import { NavLink, Link } from 'react-router-dom';
import { IconContext } from 'react-icons/lib'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link as ScrollLink } from 'react-scroll';

function NavBar(){
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const handleScroll = (event, id) => {
        event.preventDefault();
    
        const element = document.getElementById(id);
        if (element) {
          window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth',
          });
        }
      };
    return(
        // <header>
        //     <div className='navbar'>
        //         <a href='/'><img className='appLogo' src={logo}/></a>
        //         <a href="#aboutUs" className="nav-item">About Us</a>
        //         <a href="#homeTo" className="nav-item">Directory</a>
        //         <a href="#amneties" className="nav-item">Leasing</a>
        //         <a href="#map" className="nav-item">Contact Us</a>
        //     </div>
        // </header>
        <>

        {/* navbar with burger icon when screen resize */}
            <IconContext.Provider value={{color: '#3e3e40'}}>
                <div className='navbar'>
                    <div className='navbar-container'>
                        <div className='navbar-logo'>
                            <a href='/'><img className='nav_logo-img' src={logo} onClick={closeMobileMenu}/></a>
                        </div>

                        <div className='menu-icon' onClick={handleClick}>
                            {click ? <FaTimes/> : <FaBars/>}
                        </div>

                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className='nav-item'>
                                <a href="/#aboutUs" className="nav-links" onClick={closeMobileMenu}>About Us</a>
                            </li>

                            <li className='nav-item'>
                                <a href="/#homeTo" className="nav-links" onClick={closeMobileMenu}>Directory</a>
                            </li>

                            <li className='nav-item'>
                                <a href="/#amneties" className="nav-links" onClick={closeMobileMenu}>Leasing</a>
                            </li>

                            <li className='nav-item'>
                                <a href="/#mapLocation" className="nav-links" onClick={closeMobileMenu}>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    );
}

export default NavBar;
