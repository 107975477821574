import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FacebookProvider, CustomChat } from 'react-facebook';

import LandingPage from './Pages/LandingPage/LandingPage';
import Directory from './Pages/Directory/directory';
import StoreDetails from './Pages/StoreDetails/Store';
import AboutUs from './components/AboutUs/AboutUs';

function App() {
  useEffect(() => {
    // Load the Facebook SDK asynchronously
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '783481226703777',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v11.0',
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    // Wait for the SDK to be loaded before using FB
    window.addEventListener('load', function () {
      window.FB.Event.subscribe('customerchat.load', function () {
        console.log('Messenger chat plugin loaded');
      });
    });
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/AboutUs" element={<AboutUs/>} />
          <Route path="/directory" element={<Directory />} />
          <Route path="/Store" element={<StoreDetails />} />
        </Routes>
      </Router>
      <FacebookProvider appId="783481226703777">
        <CustomChat
          pageId="106034028441299"
          themeColor="#0084ff"
          loggedInGreeting="Hi! How can we help you today?"
          loggedOutGreeting="Hi! Please log in to chat with us."
        />
      </FacebookProvider>
    </div>
  );
}

export default App;