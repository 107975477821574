import React, { useEffect } from 'react';
import logo from '../../Assets/chinatown-logo.png';
import photo from '../../Assets/chinatown-photo.png';
import ct1 from '../../Assets/chinatown-header-1.jpg';
import ct2 from '../../Assets/chinatown-header-2.jpg';
import ct3 from '../../Assets/chinatown-header-3.JPG';
import ct4 from '../../Assets/chinatown-header-4.JPG';
import ct5 from '../../Assets/chinatown-header-5.JPG';
import ct6 from '../../Assets/chinatown-header-6.JPG';
import ct7 from '../../Assets/chinatown-header-7.JPG';
import ct8 from '../../Assets/chinatown-header-8.jpg';
import ct9 from '../../Assets/chinatown-header-9.jpg';
import ct10 from '../../Assets/chinatown-header-10.jpg';
import ct11 from '../../Assets/chinatown-header-11.jpg';
import '../LandingPage/LandingPage.css';
import AboutUs from '../../components/AboutUs/AboutUs';
import HomeTo from '../../components/HomeTo/HomeTo';
import Footer from '../../components/Footer/Footer';
import photoTwo from '../../Assets/photo-chinatownTwo-min.jpg'
import Carousel from 'react-bootstrap/Carousel';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import NavBar from '../../components/NavBar/NavBar';

function LandingPage() {

  
  useEffect(() => {
    // check if the URL has an anchor (e.g., /#aboutUs) and scroll to the corresponding section
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substr(1));
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, []);

  return ( 
    
    <div className="App">
      <NavBar/>
        <header className="logo-header">
          {/* <img src={logo} className="logo" alt="logo" /> */}
        </header>
          <Carousel className='header-photos'>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct1}
                alt="Image One"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct2}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct3}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct4}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct5}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct6}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct7}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct8}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct9}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct10}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
            <img
                className="d-block w-100"
                src={ct11}
                alt="Image Two"
            />
            <Carousel.Caption>
            </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
      {/* <div className='chinatownPhoto'>
       <img src={photo} className="photo" alt="photo" />
      </div> */}
      <AboutUs/>
      <HomeTo/>
      <Footer/>
      <ScrollToTop/>
    </div>    
    
  );
}

export default LandingPage;
