import React from 'react'
import './Footer.css';
import logo from '../../Assets/chinatown-logo.png'
import facebook from '../../Assets/facebook.png'
import viber from '../../Assets/viber.png'
import messenger from '../../Assets/messenger.png'


function Footer() {
  return (
    <footer>
        <div className='footerContainer'>
            <div className='footerHeader'>
                <div className='footerLogoContainer'> 
                    <img className='footerLogo' src={logo}/>
                </div>
                <div className='footerTitleContainer'>
                    <p className='footerTitle'>Powered by MYT Softdev Solutions, Inc.</p>
                </div>
                <div>
                    <a target="_blank" href='https://www.facebook.com/ChinatownEastGate'>
                    <img className='footerLogo' src={facebook}/>
                    </a>
                </div>
                <div>
                    <a target="_blank" href='tel: +63 917 186 8880'>
                    <img className='footerLogo' src={viber}/>
                    </a>
                </div>
                <div>
                    <a target="_blank" href=''>
                    <img className='footerLogo' src={messenger}/>
                    </a>
                </div>
            </div>  
        </div>
    </footer>
  );
}

export default Footer;