import React, {useState, useRef} from 'react'
import './directory.css';
import NavBar from '../../components/NavBar/NavBar';
import logo from '../../Assets/chinatown-logo.png'
import photoTwo from '../../Assets/photo-chinatownTwo-min.jpg'
import photo from '../../Assets/chinatown-photo.png'
import Footer from '../../components/Footer/Footer';
import lifestyle from '../../Assets/lifestyle.png'
import electronics from '../../Assets/electronics.png'
import food from '../../Assets/food.png'
import work from '../../Assets/work.png'
import sevenEleven from '../../Assets/seveneleven.png'
import time from '../../Assets/time.png'
import contact from '../../Assets/contact.png'
import watsons from '../../Assets/watsons-logo.jpg'
import watsons1 from '../../Assets/watsons1.jpg'
import watsons2 from '../../Assets/watsons2.jpg'
import watsons3 from '../../Assets/watsons3.jpg'
import watsons4 from '../../Assets/watsons4.jpg'
import watsons5 from '../../Assets/watsons5.jpg'
import watsons6 from '../../Assets/watsons6.jpg'
import watsons7 from '../../Assets/watsons7.jpg'
import buenas from '../../Assets/bueanas-sausages.jpeg'
import buenas1 from '../../Assets/buenas1.jpg'
import buenas2 from '../../Assets/buenas2.jpg'
import relx from '../../Assets/relx.jpg'
import relx1 from '../../Assets/relx1.jpg'
import relx2 from '../../Assets/relx2.jpg'
import relx3 from '../../Assets/relx3.jpg'
import relx4 from '../../Assets/relx4.jpg'
import relx5 from '../../Assets/relx5.jpg'
import relx6 from '../../Assets/relx6.jpg'
import relx7 from '../../Assets/relx7.jpg'
import relx8 from '../../Assets/relx8.jpg'
import relx9 from '../../Assets/relx9.jpg'
import relx10 from '../../Assets/relx10.jpg'
import relx11 from '../../Assets/relx11.jpg'
import relx12 from '../../Assets/relx12.jpg'
import relx13 from '../../Assets/relx13.jpg'
import relx14 from '../../Assets/relx14.jpg'
import forever from '../../Assets/forever-cafe.jpg'
import cafe1 from '../../Assets/cafe1.jpg'
import cafe2 from '../../Assets/cafe2.jpg'
import cafe3 from '../../Assets/cafe3.jpg'
import cafe4 from '../../Assets/cafe4.jpg'
import escco from '../../Assets/ESCCO.jpg'
import escco1 from '../../Assets/escco1.jpg'
import escco2 from '../../Assets/escco2.jpg'
import escco3 from '../../Assets/escco3.jpg'
import pj from '../../Assets/PJ-home-pizza.jpg'
import pj1 from '../../Assets/pj1.jpg'
import pj2 from '../../Assets/pj2.jpg'
import pj3 from '../../Assets/pj3.jpg'
import pj4 from '../../Assets/pj4.jpg'
import pj5 from '../../Assets/pj5.jpg'
import pj6 from '../../Assets/pj6.jpg'
import bdo from '../../Assets/bdo.jpg'
import bdo1 from '../../Assets/bdo1.jpg'
import bdo2 from '../../Assets/bdo2.jpg'
import meatmonger from '../../Assets/Meatmonger.jpg'
import meat1 from '../../Assets/meat1.jpg'
import meat2 from '../../Assets/meat2.jpg'
import meat3 from '../../Assets/meat3.jpg'
import meat4 from '../../Assets/meat4.jpg'
import meat5 from '../../Assets/meat5.jpg'
import meat6 from '../../Assets/meat6.jpg'
import meat7 from '../../Assets/meat7.jpg'
import meat8 from '../../Assets/meat8.jpg'
import barks from '../../Assets/barks-and-bubbles.jpg'
import bark1 from '../../Assets/bark1.jpg'
import bark2 from '../../Assets/bark2.jpg'
import bark3 from '../../Assets/bark3.jpg'
import bark4 from '../../Assets/bark4.jpg'
import bark5 from '../../Assets/bark5.jpg'
import bark6 from '../../Assets/bark6.jpg'
import aephoria from '../../Assets/aephoria-bar.jpg'
import aephoria1 from '../../Assets/aephoria1.jpg'
import aephoria2 from '../../Assets/aephoria2.jpg'
import aephoria3 from '../../Assets/aephoria3.jpg'
import aephoria4 from '../../Assets/aephoria4.jpg'
import aephoria5 from '../../Assets/aephoria5.jpg'
import aephoria6 from '../../Assets/aephoria6.jpg'
import aephoria7 from '../../Assets/aephoria7.jpg'
import aephoria8 from '../../Assets/aephoria8.jpg'
import aephoria9 from '../../Assets/aephoria9.jpg'
import aephoria10 from '../../Assets/aephoria10.jpg'
import aephoria11 from '../../Assets/aephoria11.jpg'
import aviary from '../../Assets/aviary.jpg'
import oskee from '../../Assets/Oskee.png'
import oskee1 from '../../Assets/oskee1.jpg'
import oskee2 from '../../Assets/oskee2.jpg'
import oskee3 from '../../Assets/oskee4.jpg'
import oskee4 from '../../Assets/oskee4.jpg'
import oskee5 from '../../Assets/oskee5.jpg'
import oskee6 from '../../Assets/oskee6.jpg'
import kurimi from '../../Assets/Kurimi.png'
import kurimi1 from '../../Assets/kurimi1.jpg'
import kurimi2 from '../../Assets/kurimi2.jpg'
import kurimi3 from '../../Assets/kurimi3.jpg'
import kurimi4 from '../../Assets/kurimi4.jpg'
import lbc from '../../Assets/lbc.png'
import lbc1 from '../../Assets/lbc1.jpg'
import lbc2 from '../../Assets/lbc2.jpg'
import lbc3 from '../../Assets/lbc3.jpg'
import lbc4 from '../../Assets/lbc4.jpg'
import globe from '../../Assets/GLOBE.jpg'
import globe1 from '../../Assets/globe1.jpg'
import globe2 from '../../Assets/globe2.jpg'
import globe3 from '../../Assets/globe3.jpg'
import globe4 from '../../Assets/globe4.jpg'
import blissfull from '../../Assets/blissfull.png'
import blissful1 from '../../Assets/blissful1.jpg'
import blissful2 from '../../Assets/blissful2.jpg'
import blissful3 from '../../Assets/blissful3.jpg'
import blissful4 from '../../Assets/blissful4.jpg'
import ebingo from '../../Assets/EBINGO.png'
import bingo1 from '../../Assets/bingo1.jpg'
import bingo2 from '../../Assets/bingo2.jpg'
import bingo3 from '../../Assets/bingo3.jpg'
import bingo4 from '../../Assets/bingo4.jpg'
import bingo5 from '../../Assets/bingo5.jpg'
import bingo6 from '../../Assets/bingo6.jpg'
import bingo7 from '../../Assets/bingo7.jpg'
import bingo8 from '../../Assets/bingo8.jpg'
import bingo9 from '../../Assets/bingo9.jpg'
import bingo10 from '../../Assets/bingo10.jpg'
import bingo11 from '../../Assets/bingo11.jpg'
import cake from '../../Assets/cake-sarang.png'
import sarang1 from '../../Assets/sarang1.jpg'
import sarang2 from '../../Assets/sarang2.jpg'
import sarang3 from '../../Assets/sarang3.jpg'
import sarang4 from '../../Assets/sarang4.jpg'
import seven from '../../Assets/7-eleven_logo.png'
import sevenEleven1 from '../../Assets/sevenEleven1.jpg'
import sevenEleven2 from '../../Assets/sevenEleven2.png'
import skin from '../../Assets/skin.jpg'
import skin1 from '../../Assets/skin1.jpg'
import skin2 from '../../Assets/skin2.jpg'
import skin3 from '../../Assets/skin3.jpg'
import skin4 from '../../Assets/skin4.jpg'
import skin5 from '../../Assets/skin5.jpg' 
import skin6 from '../../Assets/skin6.jpg'
import skin7 from '../../Assets/skin7.jpg'
import yeongchon from '../../Assets/yeongchon-logo.png'
import home from '../../Assets/home-pizza-logo.png'
import mail from '../../Assets/mail.png'
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

function Directory() {
    const navigate = useNavigate()
    const items = [
        {
            id1: 1,
            image: watsons,
            image1: watsons1,
            image2: watsons2,
            image3: watsons5,
            image4: watsons6,
            image5: watsons7,
            title: 'Watsons',
            text: '9:00 AM - 9:00 PM',
            text1: '09475188014/09178140980',
            text2:'WTCPH3948Chinatown@watsons.com.ph',
            category:"lifestyle",
            description: "Community Pharmacy",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id2: 2,
            image: buenas,
            image1: buenas1,
            image2: buenas2,
            title: 'Buenas Sausages & Fries',
            text: '9:00 AM - 9:00 PM',
            text1: '09959116798',
            text2: 'avegiel.aviles@yahoo.com',
            category:"food",
            description: "Food kiosk",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id3: 3,
            image: seven,
            image1: sevenEleven1,
            image2: sevenEleven2,
            title: '7 Eleven',
            text: '24/7',
            text1: '09489812791',
            text2: 'st4078@store.philseven.com',
            category:"food",
            description: "Convenience Store",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id4: 4,
            image: relx,
            image1: relx1,
            image2: relx2,
            image3: relx4,
            image4: relx6,
            image5: relx7,
            image6: relx8,
            image7: relx13,
            image8: relx14,
            title: 'Relx',
            text: '1:00 PM -9:00 PM',
            text1: '09027836535',
            category:"electronic",
            description: "Vape Store",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id5: 5,
            image: forever,
            image1: cafe1,
            image2: cafe2,
            title: 'Forever Cafe',
            text: '9:00 AM - 12 MIDNIGHT',
            text1: '09985908778',
            text2: 'sardandesiree9@gmail.com',
            category:"food",
            description: "Cafe",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id6: 6,
            image: escco,
            image1: escco1,
            image2: escco2,
            image3: escco3,
            title: 'ESCCO',
            text: '9:00 AM-5:00 PM',
            text1: '09064439570/09630520953',
            text2: 'escco.visayas@gmail.com/glosilballera8@gmail.com',
            category: "work",
            description: "Cooperative",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id7: 7,
            image: pj,
            image1: pj1,
            image2: pj3,
            image3: pj4,
            image4: pj6,
            title: 'P&J Home Pizza',
            text: '10:00 AM -5:30 PM',
            text1: '09279515693',
            category:"food",
            description: "Pizza House",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id8: 8,
            image: cake,
            image1: sarang1,
            image2: sarang3,
            image3: sarang4,
            title: 'Cake Sarang',
            text: '10:00 AM - 9:00 PM',
            text1: '09458537095',
            category:"food",
            description: "Korean Cake and Coffe Shop",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id9: 9,
            image: bdo,
            image1: bdo2,
            title: 'BDO Network Bank',
            text: '8:30 AM - 5:30 PM',
            text1: '09989932603',
            text2: 'bdonetworkbank.com.ph',
            category:"work",
            description: "Bank",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id10: 10,
            image: kurimi,
            image1: kurimi1,
            image2: kurimi2,
            image3: kurimi3,
            image4: kurimi4,
            title: 'Kurimi Milktea Bar',
            text: '10:00 AM - 9:00 PM',
            text1: '0535602922',
            category:"food",
            description: "MilkTea Bar",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id11: 11,
            image: meatmonger,
            image1: meat1,
            image2: meat2,
            image3: meat5,
            image4: meat8,
            title: 'Meat Monger',
            text: '9:00 AM - 10:00 PM',
            text1: '09171302894',
            category:"food",
            description: "Pub and Resto",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id12: 12,
            image: barks,
            image1: bark1,
            image2: bark2,
            image3: bark4,
            image4: bark5,
            image5: bark6,
            title: 'Barks & Bubbles Pet Grooming and Care',
            text: '9:00 AM - 6:00 PM',
            text1: '09569710801',
            text2: 'barksandbubbles001@gmail.com',
            category:"work",
            description: "Grooming",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id13: 13,
            image: globe,
            image1: globe4,
            image2: globe3,
            image3: globe2,
            title: 'Globe',
            text: '9:00 AM -7:00 PM',
            text1: '09176888878',
            text2: 'zpdnacaingay@globe.com.ph',
            category:"work",
            description: "Globe Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id14: 14,
            image: ebingo,
            image1: bingo3,
            image2: bingo4,
            image3: bingo5,
            image4: bingo10,
            title: 'E-Bingo',
            text: '9:00 AM - 10:30 PM',
            text1: '09310027322',
            text2: 'ggcormoc1458@yahoo.com',
            category:"electronic",
            description: "Amusement Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id15: 15,
            image: skin,
            image1: skin1,
            image2: skin2,
            image3: skin3,
            image4: skin4,
            image5: skin5,
            image6: skin7,
            title: 'Skin & Skin Clinic and Wellness',
            text: '9:00 AM - 9:00 PM',
            text1: '09171890711',
            text2: 'nabangkatherinerose@gmail.com',
            category:"lifestyle",
            description: "Derma Clinic & Spa Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id16: 16,
            image: blissfull,
            image1: blissful1,
            image2: blissful2,
            image3: blissful3,
            image4: blissful4,
            title: 'Blissfull Wellness Spa',
            text: '1:00 PM - 1:00 AM',
            text1: '09558439963',
            text2: 'mariloucañete@gmail.com',
            category:"lifestyle",
            description: "Massage Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id17: 17,
            image: oskee,
            image1: oskee1,
            image2: oskee2,
            image3: oskee3,
            image4: oskee6,
            title: 'Oskee Cabeltis Salon',
            text: '8:30 AM - 8:00 PM',
            text1: '09278731032',
            text2: 'Jomaroskee@yahoo.com',
            category:"lifestyle",
            description: "Cutting, Coloring Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id18: 18,
            image: lbc,
            image1: lbc1,
            image2: lbc2,
            image3: lbc3,
            title: 'LBC',
            text: '9:00 AM - 6:00 PM',
            text1: '09178066871',
            text2: 'ochi@teamlbc.com.ph',
            category:"work",
            description: "Payments, Remittance, Documents and Mail",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
        {
            id19: 19,
            image: aephoria,
            image1: aephoria2,
            image2: aephoria3,
            image3: aephoria4,
            image4: aephoria5,
            image5: aephoria6,
            image6: aephoria6,
            title: 'Aephoria The Bar',
            text: '9:00 AM - 11:00 PM',
            text1: '09674219067',
            text2: 'hausofaephoria@gmail.com',
            category:"work",
            description: "Bar",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },
    ];
    
        const [categoryFilter, setCategoryFilter] = useState(null);

        const [searchTerm, setSearchTerm] = useState(null);

        const filteredItems = categoryFilter
            ? items.filter((item) => item.category === categoryFilter 
            || item.title === searchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : items;
            
        const sectionRef = useRef(null);

        const handleCategoryClick = (category,e) => {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setCategoryFilter(category);  
            console.log(category);
            
        };

        
        

        const handleSearchTerm = (e) => {
            console.log(e.target.value);
            setSearchTerm(e.target.value);
        };

        const searchButtonRef = useRef(null);

        function handleKeyDown(event) {
            if (event.keyCode === 13) {
              searchButtonRef.current.click();
            }
          }

         

        return (
            // Directory Page
            <div className="App">
                <NavBar/>
                {/* <header className='directory-header'>
                    <Link to="/">
                        <img src={logo} className="logo" alt="logo" />
                    </Link>
                </header> */}

                <div className="search">
                    <input onKeyDown={handleKeyDown} type="text" name='search_term' id='search_term' className="searchBar" onChange={(e) => handleSearchTerm(e)} placeholder='Find a store.'/>
                    
                    <button ref={searchButtonRef} type="button" className="searchBtn" onClick={() => handleCategoryClick(searchTerm)}>SEARCH</button>
                 
                </div>
                {/* <Carousel>
                    <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={photo}
                        alt="Image One"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={1500}>
                    <img
                        className="d-block w-100"
                        src={photoTwo}
                        alt="Image Two"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                    </Carousel.Item>
                </Carousel> */}
                {/* <div className='chinatownPhoto'>
                    <img src={photo} className="photo" alt="photo" />
                </div> */}

                <div className='filter-btn-wrapper' ref={sectionRef}>
                    <button onClick={() => handleCategoryClick('lifestyle')} className={categoryFilter === "lifestyle"?'filter-btn active':'filter-btn'}  data-filter="lifestyle">
                        <img src={lifestyle} className="filter-img"></img>Lifestyle</button>
                    <button onClick={() => handleCategoryClick('electronic')} className={categoryFilter === "electronic"?'filter-btn active':'filter-btn'} data-filter="electronic">
                        <img src={electronics} className="filter-img"></img>Electronic</button>
                    <button onClick={() => handleCategoryClick('food')} className={categoryFilter === "food"?'filter-btn active':'filter-btn'} data-filter="food">
                        <img src={food} className="filter-img"></img>Food</button>
                    <button onClick={() => handleCategoryClick('work')} className={categoryFilter === "work"?'filter-btn active':'filter-btn'} data-filter="work">
                        <img src={work} className="filter-img"></img>Work</button>
                </div>
                
                <div className='card-container'>
                    {filteredItems.map((item, index) => (
                        <div key={index} data-category={item.category} className='col-3'>
                            <div className="headerimage">
                                <img className='card-image' src={item.image}></img>
                            </div>
                            <div className="title">
                                <h3>{item.title}</h3>
                            </div>
                            <div className="timepic">
                                <div className="row1"><img src={time}></img></div>
                                <div className="row1"><p>{item.text}</p></div>
                            </div>
                            <div className="contactpic">
                                <div className="row1"><img src={contact}></img></div>
                                <div className="row1"><p>{item.text1}</p></div>
                            </div>
                            <div class="card-body">
                                <button href="#" class="redirect btn-primary" onClick={() => navigate("/Store", {state: {items: item}})}>SHOW</button>
                            </div>
                        </div>
                    ))}            
                </div>

                
                <Footer />
                <ScrollToTop/>
            </div>
        );
    

   
}

export default Directory;