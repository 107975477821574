import React, {useState, useRef} from 'react'
import map from '../../Assets/map.png';
import checkIcon from '../../Assets/check-icon.png';
import sevenEleven from '../../Assets/7eleven-logo.png';
import homePizza from '../../Assets/home-pizza-logo.png';
import meatMonger from '../../Assets/meatmonger-logo.png';
import yeongChon from '../../Assets/yeongchon-logo.png';
import lantern from '../../Assets/lantern.png';
import watsons from '../../Assets/watsons-logo.jpg'
import buenas from '../../Assets/bueanas-sausages.jpeg'
import seven from '../../Assets/7-eleven_logo.png'
import relx from '../../Assets/relx.jpg'
import forever from '../../Assets/forever-cafe.jpg'
import escco from '../../Assets/ESCCO.jpg'
import pj from '../../Assets/PJ-home-pizza.jpg'
import cake from '../../Assets/cake-sarang.png'
import bdo from '../../Assets/bdo.jpg'
import kurimi from '../../Assets/Kurimi.png'
import meatmonger from '../../Assets/Meatmonger.jpg'
import barks from '../../Assets/barks-and-bubbles.jpg'
import globe from '../../Assets/GLOBE.jpg'
import ebingo from '../../Assets/EBINGO.png'
import skin from '../../Assets/skin.jpg'
import blissfull from '../../Assets/blissfull.png'
import oskee from '../../Assets/Oskee.png'
import lbc from '../../Assets/lbc.png'
import aephoria from '../../Assets/aephoria-bar.jpg'
import seafood from '../../Assets/seafood-galore.jpg'
import { useNavigate } from 'react-router-dom';
import '../HomeTo/HomeTo.css'

import watsons1 from '../../Assets/watsons1.jpg'
import watsons2 from '../../Assets/watsons2.jpg'
import watsons5 from '../../Assets/watsons5.jpg'
import watsons6 from '../../Assets/watsons6.jpg'
import watsons7 from '../../Assets/watsons7.jpg'
import buenas1 from '../../Assets/buenas1.jpg'
import buenas2 from '../../Assets/buenas2.jpg'
import sevenEleven1 from '../../Assets/sevenEleven1.jpg'
import sevenEleven2 from '../../Assets/sevenEleven2.png'
import relx1 from '../../Assets/relx1.jpg'
import relx2 from '../../Assets/relx2.jpg'
import relx4 from '../../Assets/relx4.jpg'
import relx6 from '../../Assets/relx6.jpg'
import relx7 from '../../Assets/relx7.jpg'
import relx8 from '../../Assets/relx8.jpg'
import relx13 from '../../Assets/relx13.jpg'
import relx14 from '../../Assets/relx14.jpg'
import cafe1 from '../../Assets/cafe1.jpg'
import cafe2 from '../../Assets/cafe2.jpg'
import escco1 from '../../Assets/escco1.jpg'
import escco2 from '../../Assets/escco2.jpg'
import escco3 from '../../Assets/escco3.jpg'
import pj1 from '../../Assets/pj1.jpg'
import pj3 from '../../Assets/pj3.jpg'
import pj4 from '../../Assets/pj4.jpg'
import pj6 from '../../Assets/pj6.jpg'
import bdo2 from '../../Assets/bdo2.jpg'
import sarang1 from '../../Assets/sarang1.jpg'
import sarang3 from '../../Assets/sarang3.jpg'
import sarang4 from '../../Assets/sarang4.jpg'
import kurimi1 from '../../Assets/kurimi1.jpg'
import kurimi2 from '../../Assets/kurimi2.jpg'
import kurimi3 from '../../Assets/kurimi3.jpg'
import kurimi4 from '../../Assets/kurimi4.jpg'
import meat1 from '../../Assets/meat1.jpg'
import meat2 from '../../Assets/meat2.jpg'
import meat5 from '../../Assets/meat5.jpg'
import meat8 from '../../Assets/meat8.jpg'
import bark1 from '../../Assets/bark1.jpg'
import bark2 from '../../Assets/bark2.jpg'
import bark4 from '../../Assets/bark4.jpg'
import bark5 from '../../Assets/bark5.jpg'
import bark6 from '../../Assets/bark6.jpg'
import globe2 from '../../Assets/globe2.jpg'
import globe3 from '../../Assets/globe3.jpg'
import globe4 from '../../Assets/globe4.jpg'
import bingo3 from '../../Assets/bingo3.jpg'
import bingo4 from '../../Assets/bingo4.jpg'
import bingo5 from '../../Assets/bingo5.jpg'
import bingo10 from '../../Assets/bingo10.jpg'
import skin1 from '../../Assets/skin1.jpg'
import skin2 from '../../Assets/skin2.jpg'
import skin3 from '../../Assets/skin3.jpg'
import skin4 from '../../Assets/skin4.jpg'
import skin5 from '../../Assets/skin5.jpg' 
import skin7 from '../../Assets/skin7.jpg'
import blissful1 from '../../Assets/blissful1.jpg'
import blissful2 from '../../Assets/blissful2.jpg'
import blissful3 from '../../Assets/blissful3.jpg'
import blissful4 from '../../Assets/blissful4.jpg'
import oskee1 from '../../Assets/oskee1.jpg'
import oskee2 from '../../Assets/oskee2.jpg'
import oskee3 from '../../Assets/oskee4.jpg'
import oskee6 from '../../Assets/oskee6.jpg'
import lbc1 from '../../Assets/lbc1.jpg'
import lbc2 from '../../Assets/lbc2.jpg'
import lbc3 from '../../Assets/lbc3.jpg'
import aephoria2 from '../../Assets/aephoria2.jpg'
import aephoria3 from '../../Assets/aephoria3.jpg'
import aephoria4 from '../../Assets/aephoria4.jpg'
import aephoria5 from '../../Assets/aephoria5.jpg'
import aephoria6 from '../../Assets/aephoria6.jpg'
import seafood1 from '../../Assets/seafood1.jpg'
import seafood2 from '../../Assets/seafood2.jpg'
import seafood3 from '../../Assets/seafood3.jpg'
import seafood4 from '../../Assets/seafood4.jpg'
import seafood5 from '../../Assets/seafood5.jpg'
import seafood6 from '../../Assets/seafood6.jpg'

import alab from '../../Assets/alab.jpg'
import aviary from '../../Assets/aviary-w.jpg'
import axa from '../../Assets/axa.jpg'
import kaplogs from '../../Assets/kaplogs.jpg'
import pakalz from '../../Assets/pakalz.jpg'
import paytaca from '../../Assets/paytaca.jpg'
import steve from '../../Assets/steve.jpg'
import tzo from '../../Assets/tzo.jpg'
import virtual from '../../Assets/virtual.png'

function HomeTo() {
    const navigate = useNavigate()
    const items = [
        {
            id1: 1,
            image: watsons,
            image1: watsons1,
            image2: watsons2,
            image3: watsons5,
            image4: watsons6,
            image5: watsons7,
            title: 'Watsons',
            text: '9:00 AM - 9:00 PM',
            text1: '09475188014/09178140980',
            text2:'WTCPH3948Chinatown@watsons.com.ph',
            category:"lifestyle",
            description: "Community Pharmacy",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];

    const buenasItems = [
        {
            id2: 1,
            image: buenas,
            image1: buenas1,
            image2: buenas2,
            title: 'Buenas Sausages & Fries',
            text: '9:00 AM - 9:00 PM',
            text1: '09959116798',
            text2: 'avegiel.aviles@yahoo.com',
            category:"food",
            description: "Food kiosk",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];

    const sevenItems = [
        {
            id3: 1,
            image: seven,
            image1: sevenEleven1,
            image2: sevenEleven2,
            title: '7 Eleven',
            text: '24/7',
            text1: '09489812791',
            text2: 'st4078@store.philseven.com',
            category:"food",
            description: "Convenience Store",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        const relxItems = [
        {
            id4: 1,
            image: relx,
            image1: relx1,
            image2: relx2,
            image3: relx4,
            image4: relx6,
            image5: relx7,
            image6: relx8,
            image7: relx13,
            image8: relx14,
            title: 'Relx',
            text: '1:00 PM -9:00 PM',
            text1: '09027836535',
            category:"electronic",
            description: "Vape Store",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        const foreverItems = [
        {
            id5: 1,
            image: forever,
            image1: cafe1,
            image2: cafe2,
            title: 'Forever Cafe',
            text: '9:00 AM - 12 MIDNIGHT',
            text1: '09985908778',
            text2: 'sardandesiree9@gmail.com',
            category:"food",
            description: "Cafe",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        // const esccoItems = [
        // {
        //     id6: 1,
        //     image: escco,
        //     image1: escco1,
        //     image2: escco2,
        //     image3: escco3,
        //     title: 'ESCCO',
        //     text: '9:00 AM-5:00 PM',
        //     text1: '09064439570/09630520953',
        //     text2: 'escco.visayas@gmail.com/glosilballera8@gmail.com',
        //     category: "work",
        //     description: "Cooperative",
        //     amneties1: "WiFi",
        //     amneties2: "Pet-Friendly",
        //     amneties3: "Working Space",
        //     amneties4: "Charging Area"
        // },];
        const pjItems = [
        {
            id7: 1,
            image: pj,
            image1: pj1,
            image2: pj3,
            image3: pj4,
            image4: pj6,
            title: 'P&J Home Pizza',
            text: '10:00 AM -5:30 PM',
            text1: '09279515693',
            category:"food",
            description: "Pizza House",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        // const cakeItems = [
        // {
        //     id8: 1,
        //     image: cake,
        //     image1: sarang1,
        //     image2: sarang3,
        //     image3: sarang4,
        //     title: 'Cake Sarang',
        //     text: '10:00 AM - 9:00 PM',
        //     text1: '09458537095',
        //     category:"food",
        //     description: "Korean Cake and Coffe Shop",
        //     amneties1: "WiFi",
        //     amneties2: "Pet-Friendly",
        //     amneties3: "Working Space",
        //     amneties4: "Charging Area"
        // },];
        const bdoItems = [
        {
            id9: 1,
            image: bdo,
            image1: bdo2,
            title: 'BDO Network Bank',
            text: '8:30 AM - 5:30 PM',
            text1: '09989932603',
            text2: 'bdonetworkbank.com.ph',
            category:"work",
            description: "Bank",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        // const kurimiItems = [
        // {
        //     id10: 1,
        //     image: kurimi,
        //     image1: kurimi1,
        //     image2: kurimi2,
        //     image3: kurimi3,
        //     image4: kurimi4,
        //     title: 'Kurimi Milktea Bar',
        //     text: '10:00 AM - 9:00 PM',
        //     text1: '0535602922',
        //     category:"food",
        //     description: "MilkTea Bar",
        //     amneties1: "WiFi",
        //     amneties2: "Pet-Friendly",
        //     amneties3: "Working Space",
        //     amneties4: "Charging Area"
        // },];
        const meatItems = [
        {
            id11: 1,
            image: meatmonger,
            image1: meat1,
            image2: meat2,
            image3: meat5,
            image4: meat8,
            title: 'Meat Monger',
            text: '9:00 AM - 10:00 PM',
            text1: '09171302894',
            category:"food",
            description: "Pub and Resto",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        const barksItems = [
        {
            id12: 1,
            image: barks,
            image1: bark1,
            image2: bark2,
            image3: bark4,
            image4: bark5,
            image5: bark6,
            title: 'Barks & Bubbles Pet Grooming and Care',
            text: '9:00 AM - 6:00 PM',
            text1: '09569710801',
            text2: 'barksandbubbles001@gmail.com',
            category:"work",
            description: "Grooming",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        const globeItems = [
        {
            id13: 1,
            image: globe,
            image1: globe4,
            image2: globe3,
            image3: globe2,
            title: 'Globe',
            text: '9:00 AM -7:00 PM',
            text1: '09176888878',
            text2: 'zpdnacaingay@globe.com.ph',
            category:"work",
            description: "Globe Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        const ebingoItems = [
        {
            id14: 1,
            image: ebingo,
            image1: bingo3,
            image2: bingo4,
            image3: bingo5,
            image4: bingo10,
            title: 'E-Bingo',
            text: '9:00 AM - 10:30 PM',
            text1: '09310027322',
            text2: 'ggcormoc1458@yahoo.com',
            category:"electronic",
            description: "Amusement Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        const skinItems = [
        {
            id15: 1,
            image: skin,
            image1: skin1,
            image2: skin2,
            image3: skin3,
            image4: skin4,
            image5: skin5,
            image6: skin7,
            title: 'Skin & Skin Clinic and Wellness',
            text: '9:00 AM - 9:00 PM',
            text1: '09171890711',
            text2: 'nabangkatherinerose@gmail.com',
            category:"lifestyle",
            description: "Derma Clinic & Spa Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        const blissfullItems = [
        {
            id16: 1,
            image: blissfull,
            image1: blissful1,
            image2: blissful2,
            image3: blissful3,
            image4: blissful4,
            title: 'Blissfull Wellness Spa',
            text: '1:00 PM - 1:00 AM',
            text1: '09558439963',
            text2: 'mariloucañete@gmail.com',
            category:"lifestyle",
            description: "Massage Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        const oskeeItems = [
        {
            id17: 1,
            image: oskee,
            image1: oskee1,
            image2: oskee2,
            image3: oskee3,
            image4: oskee6,
            title: 'Oskee Cabeltis Salon',
            text: '8:30 AM - 8:00 PM',
            text1: '09278731032',
            text2: 'Jomaroskee@yahoo.com',
            category:"lifestyle",
            description: "Cutting, Coloring Services",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];
        // const lbcItems = [
        // {
        //     id18: 1,
        //     image: lbc,
        //     image1: lbc1,
        //     image2: lbc2,
        //     image3: lbc3,
        //     title: 'LBC',
        //     text: '9:00 AM - 6:00 PM',
        //     text1: '09178066871',
        //     text2: 'ochi@teamlbc.com.ph',
        //     category:"work",
        //     description: "Payments, Remittance, Documents and Mail",
        //     amneties1: "WiFi",
        //     amneties2: "Pet-Friendly",
        //     amneties3: "Working Space",
        //     amneties4: "Charging Area"
        // },];
        const aephoriaItems = [
        {
            id19: 1,
            image: aephoria,
            image1: aephoria2,
            image2: aephoria3,
            image3: aephoria4,
            image4: aephoria5,
            image5: aephoria6,
            image6: aephoria6,
            title: 'Aephoria The Bar',
            text: '9:00 AM - 11:00 PM',
            text1: '09674219067',
            text2: 'hausofaephoria@gmail.com',
            category:"work",
            description: "Bar",
            amneties1: "WiFi",
            amneties2: "Pet-Friendly",
            amneties3: "Working Space",
            amneties4: "Charging Area"
        },];

        // const seafoodGaloreItems = [
        //     {
        //         id19: 1,
        //         image: seafood,
        //         image1: seafood1,
        //         image2: seafood2,  
        //         image3: seafood3,
        //         image4: seafood4,
        //         image5: seafood5,
        //         image6: seafood6,
        //         title: 'Seafood Galore Ormoc',
        //         text: '11am - 10pm (DAILY)',
        //         text1: '0998 844 8787',
        //         text2: 'Seafoodgalore@yahoo.com',
        //         category:"food",
        //         description: "Restaurant",
        //         amneties1: "Pet-Friendly",
        //         amneties2: "Working Space",
        //         amneties3: "",
        //         amneties4: "",

        //     },];

        const [categoryFilter] = useState(null);

        const [searchTerm] = useState(null);

        const filteredItems = categoryFilter
            ? items.filter((item) => item.category === categoryFilter 
            || item.title === searchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : items;
            
        const filteredItemsForStore = filteredItems.map((item)=> {return item})
        //
        const [buenasCategoryFilter] = useState(null);

        const [buenasSearchTerm] = useState(null);

        const buenasFilteredItems = buenasCategoryFilter
            ? buenasItems.filter((item) => item.category === buenasCategoryFilter 
            || item.title === buenasSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : buenasItems;
            
        const buenasFilteredItemsForStore = buenasFilteredItems.map((item)=> {return item})
        //
        const [sevenCategoryFilter] = useState(null);

        const [sevenSearchTerm] = useState(null);

        const sevenFilteredItems = sevenCategoryFilter
            ? sevenItems.filter((item) => item.category === sevenCategoryFilter 
            || item.title === sevenSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : sevenItems;
            
        const sevenFilteredItemsForStore = sevenFilteredItems.map((item)=> {return item})
        //
        const [relxCategoryFilter] = useState(null);

        const [relxSearchTerm] = useState(null);

        const relxFilteredItems = relxCategoryFilter
            ? relxItems.filter((item) => item.category === relxCategoryFilter 
            || item.title === relxSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : relxItems;
            
        const relxFilteredItemsForStore = relxFilteredItems.map((item)=> {return item})
        //
        const [foreverCategoryFilter] = useState(null);

        const [foreverSearchTerm] = useState(null);

        const foreverFilteredItems = foreverCategoryFilter
            ? foreverItems.filter((item) => item.category === foreverCategoryFilter 
            || item.title === foreverSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : foreverItems;
            
        const foreverFilteredItemsForStore = foreverFilteredItems.map((item)=> {return item})
        //
        // const [esccoCategoryFilter] = useState(null);

        // const [esccoSearchTerm] = useState(null);

        // const esccoFilteredItems = esccoCategoryFilter
        //     ? esccoItems.filter((item) => item.category === esccoCategoryFilter 
        //     || item.title === esccoSearchTerm 
        //     || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
        //     || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
        //     : esccoItems;
            
        // const esccoFilteredItemsForStore = esccoFilteredItems.map((item)=> {return item})
        //
        const [pjCategoryFilter] = useState(null);

        const [pjSearchTerm] = useState(null);

        const pjFilteredItems = pjCategoryFilter
            ? pjItems.filter((item) => item.category === pjCategoryFilter 
            || item.title === pjSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : pjItems;
            
        const pjFilteredItemsForStore = pjFilteredItems.map((item)=> {return item})
        //
        // const [cakeCategoryFilter] = useState(null);

        // const [cakeSearchTerm] = useState(null);

        // const cakeFilteredItems = cakeCategoryFilter
        //     ? cakeItems.filter((item) => item.category === cakeCategoryFilter 
        //     || item.title === cakeSearchTerm 
        //     || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
        //     || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
        //     : cakeItems;
            
        // const cakeFilteredItemsForStore = cakeFilteredItems.map((item)=> {return item})
        //
        const [bdoCategoryFilter] = useState(null);

        const [bdoSearchTerm] = useState(null);

        const bdoFilteredItems = bdoCategoryFilter
            ? bdoItems.filter((item) => item.category === bdoCategoryFilter 
            || item.title === bdoSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : bdoItems;
            
        const bdoFilteredItemsForStore = bdoFilteredItems.map((item)=> {return item})
        //
        // const [kurimiCategoryFilter] = useState(null);

        // const [kurimiSearchTerm] = useState(null);

        // const kurimiFilteredItems = kurimiCategoryFilter
        //     ? kurimiItems.filter((item) => item.category === kurimiCategoryFilter 
        //     || item.title === kurimiSearchTerm 
        //     || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
        //     || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
        //     : kurimiItems;
            
        // const kurimiFilteredItemsForStore = kurimiFilteredItems.map((item)=> {return item})
        //
        const [meatCategoryFilter] = useState(null);

        const [meatSearchTerm] = useState(null);

        const meatFilteredItems = meatCategoryFilter
            ? meatItems.filter((item) => item.category === meatCategoryFilter 
            || item.title === meatSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : meatItems;
            
        const meatFilteredItemsForStore = meatFilteredItems.map((item)=> {return item})
        //
        const [barksCategoryFilter] = useState(null);

        const [barksSearchTerm] = useState(null);

        const barksFilteredItems = barksCategoryFilter
            ? barksItems.filter((item) => item.category === barksCategoryFilter 
            || item.title === barksSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : barksItems;
            
        const barksFilteredItemsForStore = barksFilteredItems.map((item)=> {return item})
        //
        const [globeCategoryFilter] = useState(null);

        const [globeSearchTerm] = useState(null);

        const globeFilteredItems = globeCategoryFilter
            ? globeItems.filter((item) => item.category === globeCategoryFilter 
            || item.title === globeSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : globeItems;
            
        const globeFilteredItemsForStore = globeFilteredItems.map((item)=> {return item})
        //
        const [ebingoCategoryFilter] = useState(null);

        const [ebingoSearchTerm] = useState(null);

        const ebingoFilteredItems = ebingoCategoryFilter
            ? ebingoItems.filter((item) => item.category === ebingoCategoryFilter 
            || item.title === ebingoSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : ebingoItems;
            
        const ebingoFilteredItemsForStore = ebingoFilteredItems.map((item)=> {return item})
        //
        const [skinCategoryFilter] = useState(null);

        const [skinSearchTerm] = useState(null);

        const skinFilteredItems = skinCategoryFilter
            ? skinItems.filter((item) => item.category === skinCategoryFilter 
            || item.title === skinSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : skinItems;
            
        const skinFilteredItemsForStore = skinFilteredItems.map((item)=> {return item})
        //
        const [blissfullCategoryFilter] = useState(null);

        const [blissfullSearchTerm] = useState(null);

        const blissfullFilteredItems = blissfullCategoryFilter
            ?blissfullItems.filter((item) => item.category === blissfullCategoryFilter 
            || item.title === blissfullSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : blissfullItems;
            
        const blissfullFilteredItemsForStore = blissfullFilteredItems.map((item)=> {return item})
        //
        const [oskeeCategoryFilter] = useState(null);

        const [oskeeSearchTerm] = useState(null);

        const oskeeFilteredItems = oskeeCategoryFilter
            ?oskeeItems.filter((item) => item.category === oskeeCategoryFilter 
            || item.title === oskeeSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : oskeeItems;
            
        const oskeeFilteredItemsForStore = oskeeFilteredItems.map((item)=> {return item})
        //
        // const [lbcCategoryFilter] = useState(null);

        // const [lbcSearchTerm] = useState(null);

        // const lbcFilteredItems = lbcCategoryFilter
        //     ?lbcItems.filter((item) => item.category === lbcCategoryFilter 
        //     || item.title === lbcSearchTerm 
        //     || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
        //     || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
        //     : lbcItems;
            
        // const lbcFilteredItemsForStore = lbcFilteredItems.map((item)=> {return item})
        //
        const [aephoriaCategoryFilter] = useState(null);

        const [aephoriaSearchTerm] = useState(null);

        const aephoriaFilteredItems = aephoriaCategoryFilter
            ?aephoriaItems.filter((item) => item.category === aephoriaCategoryFilter 
            || item.title === aephoriaSearchTerm 
            || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
            || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
            : aephoriaItems;
            
        const aephoriaFilteredItemsForStore = aephoriaFilteredItems.map((item)=> {return item})
        //

        // const [seafoodGaloreCategoryFilter] = useState(null);

        // const [seafoodGaloreSearchTerm] = useState(null);

        // const seafoodGaloreFilteredItems = seafoodGaloreCategoryFilter
        //     ?seafoodGaloreItems.filter((item) => item.category === seafoodGaloreCategoryFilter 
        //     || item.title === seafoodGaloreSearchTerm 
        //     || (item.title || '').toUpperCase() === (searchTerm || '').toUpperCase()
        //     || (item.title || '').toLowerCase() === (searchTerm || '').toLowerCase())
        //     : seafoodGaloreItems;
            
        // const seafoodGaloreFilteredItemsForStore = seafoodGaloreFilteredItems.map((item)=> {return item})

        

  return (

    <div className='hometo-container' id='homeTo'>
        <div>
            <div>
                <div className='homeTo-header'>
                    <h2 className='homeTo'>Home To</h2>
                </div>
                <div className='homeTo-header'>
                    <span className='hometo-paragraph'>
                        Click the logo for more information
                    </span>
                </div>
            </div>
            <div className='home-col-container'>
                <div className='home-col'>
                    <img src={watsons} className='home-logo' onClick={() => navigate("/Store", {state: {items: filteredItemsForStore[0]}})}></img>
                    <div className='naming'>Watsons</div>
                </div>
                {/* <div className='home-col'>
                    <img src={buenas} className='home-logo' onClick={() => navigate("/Store", {state: {items: buenasFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Buenas Sausages & Fries</div>
                </div> */}
                <div className='home-col'>
                    <img src={seven} className='home-logo' onClick={() => navigate("/Store", {state: {items: sevenFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>7 Eleven</div>
                </div>
                <div className='home-col'>
                    <img src={relx} className='home-logo' onClick={() => navigate("/Store", {state: {items: relxFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Relx</div>
                </div>
                <div className='home-col'>
                    <img src={forever} className='home-logo' onClick={() => navigate("/Store", {state: {items: foreverFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Forever Cafe</div>
                </div>            
                {/*<div className='home-col'>
                    <img src={escco} className='home-logo' onClick={() => navigate("/Store", {state: {items: esccoFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>ESCCO</div>
                </div>*/}
                {/* <div className='home-col'>
                    <img src={pj} className='home-logo' onClick={() => navigate("/Store", {state: {items: pjFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>P&J Home Pizza</div>
                </div> */}
                {/* <div className='home-col'>
                    <img src={cake} className='home-logo' onClick={() => navigate("/Store", {state: {items: cakeFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Cake Sarang</div>
                </div> */}
                <div className='home-col'>
                    <img src={bdo} className='home-logo' onClick={() => navigate("/Store", {state: {items: bdoFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>BDO Network Bank</div>
                </div>
                {/* <div className='home-col'>
                    <img src={kurimi} className='home-logo' onClick={() => navigate("/Store", {state: {items: kurimiFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Kurimi Milktea Bar</div>
                </div> */}
                {/* <div className='home-col'>
                    <img src={meatmonger} className='home-logo' onClick={() => navigate("/Store", {state: {items: meatFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Meat Monger</div>
                </div> */}
                <div className='home-col'>
                    <img src={barks} className='home-logo' onClick={() => navigate("/Store", {state: {items: barksFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Barks & Bubbles Pet Grooming and Care</div>
                </div>
                <div className='home-col'>
                    <img src={globe} className='home-logo' onClick={() => navigate("/Store", {state: {items: globeFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Globe</div>
                </div>
                <div className='home-col'>
                    <img src={ebingo} className='home-logo' onClick={() => navigate("/Store", {state: {items: ebingoFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>E-Bingo</div>
                </div>            
                <div className='home-col'>
                    <img src={skin} className='home-logo' onClick={() => navigate("/Store", {state: {items: skinFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Skin & Skin Clinic and Wellness</div>
                </div>
                <div className='home-col'>
                    <img src={blissfull} className='home-logo' onClick={() => navigate("/Store", {state: {items: blissfullFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Blissfull Wellness Spa</div>
                </div>
                <div className='home-col'>
                    <img src={oskee} className='home-logo' onClick={() => navigate("/Store", {state: {items: oskeeFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Oskee Cabeltis Salon</div>
                </div>
                <div className='home-col'>
                    <img src={alab} className='home-logo'></img>
                    <div className='naming'>A-Lab</div>
                </div>
                <div className='home-col'>
                    <img src={aviary} className='home-logo'></img>
                    <div className='naming'>Aviary</div>
                </div>
                <div className='home-col'>
                    <img src={axa} className='home-logo' ></img>
                    <div className='naming'>AXA</div>
                </div>
                <div className='home-col'>
                    <img src={kaplogs} className='home-logo' ></img>
                    <div className='naming'>Kaplogs</div>
                </div>
                <div className='home-col'>
                    <img src={pakalz} className='home-logo' ></img>
                    <div className='naming'>Pakal'z</div>
                </div>
                <div className='home-col'>
                    <img src={paytaca} className='home-logo' ></img>
                    <div className='naming'>Paytaca</div>
                </div>
                <div className='home-col'>
                    <img src={steve} className='home-logo' ></img>
                    <div className='naming'>Steve's Lechon Baka</div>
                </div>
                <div className='home-col'>
                    <img src={tzo} className='home-logo'></img>
                    <div className='naming'>Tzo Grill House</div>
                </div>
                <div className='home-col'>
                    <img src={virtual} className='home-logo' ></img>
                    <div className='naming'>Virtual Staffing Solutions</div>
                </div>
                {/* <div className='home-col'>
                    <img src={lbc} className='home-logo' onClick={() => navigate("/Store", {state: {items: lbcFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>LBC</div>
                </div> */}
                {/* <div className='home-col'>
                    <img src={seafood} className='home-logo' onClick={() => navigate("/Store", {state: {items: seafoodGaloreFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Seafood Galore Ormoc</div>
                </div> */}
                {/* <div className='home-col'>
                    <img src={aephoria} className='home-logo' onClick={() => navigate("/Store", {state: {items: aephoriaFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Aephoria The Bar</div>
                </div> */}
                {/* <div className='home-col'>
                    <img src={aephoria} className='home-logo'></img>
                </div> */}
            </div>
            {/* <div className='home-col-container2'>
                <div className='home-col'>
                    <img src={aephoria} className='home-logo-two' onClick={() => navigate("/Store", {state: {items: aephoriaFilteredItemsForStore[0]}})}></img>
                    <div className='naming'>Aephoria The Bar</div>
                </div>
            </div> */}
            <div className='paragraphContainer'>
                <p className='headerP'>
                    Here in Chinatown Eastgate, we continue to <span className='homeTo-footer'>share our dreams and success with our partners</span>
                </p>
            </div>
        </div>

        {/* HomePage design that redirects to Directory Page */}
        {/* <div className='lantern-home'>
            <div>
                <img src={lantern} className='lantern'/>
            </div>
            <div>
                <div className='header-container'>
                    <h1 className='header'>
                        Celebrate life and enjoy in
                    </h1>
                    <h1 className='header'>
                        Chinatown Eastgate!
                    </h1>
                </div>
                <div className='header-container'>
                    <p className='lantern-paragraph' align="justify">
                        Interested on getting to knoe our partners and spending time with us?<br></br> Visit our store directory now and get to see the products our partners offer
                    </p>
                </div>
                <div className='header-container'>
                    <button className='take-btn' onClick={()=>navigate("/directory")}>Take me there</button>
                </div>
            </div>
        </div> */}

        <div className='row-home-amneties' id='amneties'>
                <div className='amneties' >
                    <div className='amneties-header'>
                        <h3>AMENITIES</h3>
                    </div>
                    <div className='amneties-info'>
                        <div className='amneties-info-wrapper'>
                            <img src={checkIcon} className='amnetiesIcon'/>
                            <p>24 Hour Security</p>
                        </div>
                        <div className='amneties-info-wrapper'>
                            <img src={checkIcon} className='amnetiesIcon'/>
                            <p>Backup Power Generator</p>
                        </div>
                        <div className='amneties-info-wrapper'>
                            <img src={checkIcon} className='amnetiesIcon'/>
                            <p>Service Alley Areas</p>
                        </div>
                        <div className='amneties-info-wrapper'>
                            <img src={checkIcon} className='amnetiesIcon'/>
                            <p>Waste Disposal System</p>
                        </div>
                        <div className='amneties-info-wrapper'>
                            <img src={checkIcon} className='amnetiesIcon'/>
                            <p>Fire Alarm System</p>
                        </div>
                        <div className='amneties-info-wrapper'>
                            <img src={checkIcon} className='amnetiesIcon'/>
                            <p>Loading and Unloading Dock</p>
                        </div>
                        <div className='amneties-info-wrapper'>
                            <img src={checkIcon} className='amnetiesIcon'/>
                            <p>PWD Access on all Floors</p>
                        </div>
                    </div>
                </div>

                <div className='amnetiesContainer'>
                    <div className='amneties-paragraph'>
                        <div>
                            <h3>PARKING IS A NECESSITY, NOT A LUXURY</h3>
                        </div>
                        <div>
                            <p align="justify">
                                Customer satisfaction begins with ample and accesible parking. With a dedicated lower ground level carpark, Chinatown East Gate recognizes that parking is a necessity for efficiency
                            </p>
                        </div>
                    </div>
                    <div className='amneties-paragraph'>
                        <div>
                            <h3>RETHINKING THE COMMON AREAS</h3>
                        </div>
                        <div>
                            <p align="justify">
                                In this 8,700SQM development, only 32% is allocated rentable space. Chinatown East Gates's sprawling building design promotes green and spacious outdoors for lifestyle and cultural activities - creating a harmonious integration of business and leisure
                            </p>
                        </div>
                    </div>
                </div>
        </div>
        
        <div>
            <div className='mapPhoto' id="mapLocation">
                <img src={map} className='photo' alt="photo" />
            </div>
        </div>

    </div>
   
    
  );
}

export default HomeTo;
